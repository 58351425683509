import styled from 'styled-components';
import { themeGet } from 'styled-system';

const TopicsPage = styled.div`
  width: 100%;
`;

export const FeaturedTopics = styled.div`
  margin: 50px 0 71px;
  display: flex;
  flex-flow: row wrap;
`;

export const FeaturedTopic = styled.div`
  width: 140px;
  height: 140px;
  border: 1px solid ${themeGet('color.3')};
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: ${themeGet('color.3')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 600px) {
    width: 220px;
    height: 220px;
    font-size: 20px;
  }

  dl {
    dt {
      font-size: 48px;
      text-align: center;
      font-weight: 700;
    }
  }
`;

export default TopicsPage;
